<template>
  <div class="el-pagination">
    <span class="el-pagination__total"> Записей: {{ totalCount }} </span>
    <span class="ml-4 el-pagination__total">
      Показано:
      <el-select v-model="pageSizeLocal" size="mini" style="width: 75px;">
        <el-option
          v-for="(item, $index) in pageSizes"
          :key="$index"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </span>
    <span class="ml-4 el-pagination__total">
        <button
          type="button"
          class="btn-prev"
          v-if="totalPages > 1"
          :disabled="currentPage <= 1"
          @click="setPage(currentPage - 1)">
            <i class="el-icon el-icon-arrow-left"></i>
        </button>
        <ul class="el-pager">
          <li v-if="totalPages > 1" @click="setPage(1)" :class="{active : currentPage === 1}">
            1
          </li>
          <li v-if="currentPage > 4">
            ...
          </li>
          <template v-for="pageNumber in totalPages-1">
            <li :key="pageNumber"
                   v-if="pageNumber > 1 && (Math.abs(pageNumber - currentPage) < 3
                        || (currentPage < 4 && pageNumber < 7)
                        || (currentPage > totalPages - 3 && pageNumber > totalPages - 6))"
                   :class="{active : currentPage === pageNumber}"
                   @click="setPage(pageNumber)">
               {{ pageNumber }}
            </li>
          </template>
          <li v-if="currentPage < totalPages - 3">
                ...
          </li>
          <li v-if="totalPages > 1" @click="setPage(totalPages)" :class="{active : currentPage === totalPages}">
            {{ totalPages }}
          </li>
        </ul>
        <button
          type="button"
          class="btn-next"
          v-if="totalPages > 1"
          :disabled="currentPage == totalPages"
          @click="setPage(currentPage + 1)">
            <i class="el-icon el-icon-arrow-right"></i>
        </button>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    disabled: { type: Boolean, default: () => false },
    totalCount: { type: Number, default: () => 0 },
    pageSize: { type: Number, default: () => 10 },
    currentPage: { type: Number, default: () => 1 },
  },
  computed: {
    pageSizeLocal: {
      get() {
        return this.pageSize;
      },
      set(value) {
        this.$emit('update:pageSize', value);
        this.$emit('size-change', value);
      }
    },
    currentPageLocal: {
      get() {
        return this.pageSize;
      },
      set(value) {
        this.$emit('update:currentPage', value);
        this.$emit('current-change', value);
      }
    },
    totalPages() {
      return Math.ceil(this.totalCount === 0 ? 1 : this.totalCount / this.pageSize);
    },
  },
  data() {
    return {
      pageSizes: [1, 5, 10, 20, 50, 100],
    };
  },
  watch: {
    totalPages: {
      handler(newVal) {
        if (this.currentPage > newVal) {
          this.currentPageLocal = 1;
        }
      },
      deep: true
    },
  },
  methods: {
    setPage(num) {
      this.currentPageLocal = num;
    }
  }
};
</script>

<style scoped>

</style>
