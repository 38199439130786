<template>
  <el-dialog
    class="news-dialog"
    title="Новости"
    :visible.sync="dialogVisible"
    top="3rem"
    width="80%"
    :close-on-click-modal="false"
  >
  <!-- <el-divider/> -->
  <el-collapse @change="makeRead">
    <el-collapse-item v-for="news in filteredNews" :key="news.id" :name="news.id">
      <template slot="title">
        <i v-if="news.isRead" class="mr-3"><ReadNotifIcon/></i>
        <i v-else class="mr-3"><NewNotifIcon /></i>
        <div class="mr-3 news-header" style="display: inline-block; float: left;">{{news.header}}</div>
      </template>
      <el-row style="mt-1">
        <!-- <el-col :span="18">
          <div style="display: inline-block; float: left; color: #3078bf">{{news.header}}</div>
        </el-col> -->
        <el-col :span="6">
          <div style="display: inline-block; float: left;" >{{formatDate(news.notificationTime)}}</div>
        </el-col>
      </el-row>
      <el-row class="mt-0 el-tiptap-editor__content">
        <div v-html="news.description"></div>
      </el-row>
    </el-collapse-item>
  </el-collapse>
  <!-- <div v-for="news in filteredNews" :key="news.id">
    <el-row style="mt-1">
      <el-col :span="18">
        <div style="display: inline-block; float: left; color: #3078bf">{{news.header}}</div>
      </el-col>
      <el-col :span="6">
        <div style="display: inline-block; float: right; #828282" >{{formatDate(news.notificationTime)}}</div>
      </el-col>
    </el-row>
    <el-row>
      <div style="float: left;" v-html="news.description"></div>
    </el-row>
    <el-divider/>
  </div> -->
  </el-dialog>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import '@/assets/quill.scss';
import 'element-tiptap/lib/index.css';
import ReadNotifIcon from '@/assets/icons/read-ellipse.svg';
import NewNotifIcon from '@/assets/icons/new-ellipse.svg';


export default {
  name: 'News',
  props: ['value'],
  components: {
    ReadNotifIcon,
    NewNotifIcon,
  },
    data() {
    return {
      selectedNews: null,
      isReadFilter: null,
    };
  },
  computed: {
    ...mapGetters('news', ['news']),
    ...mapGetters('identity', ['userTimeZone']),
    filteredNews() {
      if (this.isReadFilter === null) {
        return this.news;
      }

      return this.news.filter((n) => n.isRead === this.isReadFilter);
    },
    dialogVisible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    ...mapActions('news', [
      'makeNewsRead', 'makeAllUserNewsRead'
    ]),
    close() {
      this.dialogVisible = false;
    },
    formatDate(date) {
      return moment(date).add(this.userTimeZone, 'hours').format('DD.MM.YYYY HH:mm');
    },
    makeRead(items) {
      items.forEach((item) => {
        const isReadNews = this.news.filter((x) => x.id === item);
        isReadNews.forEach(async (n) => {
          if (n.isRead === false) {
            await this.makeNewsRead(n.id);
          }
        });
      });
    }
  },
};
</script>

<style scoped>

</style>
