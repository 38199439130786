import axios from 'axios';

export default {
  uploadUserFile(formData, config) {
    return axios.post('/api/UserUpload/UploadUserFile',
      formData, config);
  },
  getUserUploads(pageNumber, pageSize, sortField, sortDesc, dateTimeFilterFrom, dateTimeFilterTo, userFilter = false) {
    return axios.get('/api/UserUpload/GetUserUploads', {
      params: {
        pageNumber,
        pageSize,
        sortField,
        sortDesc,
        dateTimeFilterFrom,
        dateTimeFilterTo,
        userFilter
      }
    });
  },
  searchUserUploads(eventSourceTypeId, searchString) {
    return axios.get('/api/UserUpload/SearchByName', {
      params: {
        eventSourceTypeId,
        searchString
      }
    });
  },
  importUserXml(fileId) {
    return axios.post('/api/UserUpload/ImportUserXml', { fileId });
  },
  importUserXmlv2(data) {
    return axios.post('/api/UserUpload/ImportUserXml', data);
  },
  deleteUserUpload: (id) => axios.delete(`/api/UserUpload/${id}`),
};

