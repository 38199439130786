<template>

  <el-dialog
    class="documents-dialog"
    title="Эксплуатационная документация"
    :visible.sync="dialogVisible"
    top="3rem"
    width="80%"
    :close-on-click-modal="false"
    @close="close"
  >
  <!-- <el-divider/> -->

  <div>
    <el-row class="m-0" :gutter="20">
      <el-col :span="12">
        <pagination
          style="text-align: center"
          :total-count="totalCount"
          :page-size.sync="pageSize"
          :current-page.sync="pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-col>
    </el-row>
  <el-table v-loading="loading"
                :data="usefulDocuments"
                style="width: 100%;"
                height="60vh"
                :default-sort = "{prop: 'notificationTime', order: 'descending'}"
                @sort-change="handleSortChange">
        <el-table-column prop="name"
                         label="Заголовок"
                         sortable="custom"
                         min-width="75">
        </el-table-column>
        <el-table-column prop="fileName"
                         label="Имя файла"
                         sortable="custom"
                         min-width="60">
        </el-table-column>
        <el-table-column
                         label="Скачать"
                         min-width="20">
            <template slot-scope="scope">
                         <el-button type="text" icon="el-icon-download" @click="handleDownload(scope.row)">Скачать</el-button>
            </template>
        </el-table-column>
      </el-table>
  </div>
    <div style="text-align: left;">Версия системы: {{sysInfo.version}}</div>
  </el-dialog>
</template>

<script>
import '@/assets/quill.scss';
import usefulDocumentsApi from '@/api/usefuldocuments';
import pagination from '@/components/Ui/Pagination';
import formatters from '@/mixins/formatters.js';
import tableResize from '@/mixins/tableResize';
import systemInfo from '@/api/SystemInfo';

  export default {
    name: 'Documents',
    props: ['value'],
    mixins: [formatters, tableResize],
    components: { pagination },
    data() {
      return {
        loading: false,
        usefulDocuments: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
        sortField: 'name',
        sortDesc: true,
        updatedFlag: false,
        sysInfo: {
          version: ''
        }
      };
    },
    async created() {
      const sysInfo = await systemInfo.getSystemInfo();
      if (sysInfo.data) {
        this.sysInfo = sysInfo.data;
      }
    },
    computed: {
      dialogVisible: {
      get() {
        return this.value;
      },
      async set(val) {
        this.$emit('input', val);
      },
    },
    },
    async updated() {
      if (this.dialogVisible && !this.updatedFlag) {
        this.updatedFlag = true;
        this.tableHeight = this.getTableHeight();
        await this.getAdministrationUsefulDocuments();
      }
    },
    methods: {
      async getAdministrationUsefulDocuments() {
        this.loading = true;
        const res = await usefulDocumentsApi.getUserUsefulDocuments(
          this.pageNumber,
          this.pageSize,
          this.sortField,
          this.sortDesc
        );
        if (res.data) {
          this.usefulDocuments = res.data.items;
          this.totalCount = res.data.totalCount;
        }
        this.loading = false;
      },
      async handleSizeChange() {
        this.pageNumber = 1;
        await this.getAdministrationUsefulDocuments();
      },
      async handleCurrentChange() {
        await this.getAdministrationUsefulDocuments();
      },
      async handleSortChange(event) {
        this.sortField = event.prop;
        this.sortDesc = event.order === 'descending';
        await this.getAdministrationUsefulDocuments();
      },
      async handleDownload(usefulDocuments) {
        await usefulDocumentsApi.downloadDocument(usefulDocuments.id, usefulDocuments.fileName);
      },
      close() {
        this.dialogVisible = false;
        this.updatedFlag = false;
      },
    }
  };
</script>

<style scoped>

</style>
