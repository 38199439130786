import Vue from 'vue';

import useWindowSize from 'vue-window-size';
import ElementUI from 'element-ui';
import moment from 'moment';
import VueTheMask from 'vue-the-mask';
import identitySrv from '@/utilities/axios/identity';
import interceptorsSrv from '@/utilities/axios/interceptors';


// ElementUI
/* import 'element-ui/lib/theme-chalk/index.css'; */
/* import themeSw from '@/utilities/themeSwitcher'; */
import locale from '@/utilities/elementUI/ru';

// https://momentjs.com/

import App from './App.vue';
import router from './router';
import store from './store';

/* scss */
import '@/assets/spacing.scss';
import '@/assets/login.scss';

/* axios */
interceptorsSrv();

Vue.use(ElementUI, { locale });
Vue.use(useWindowSize);
Vue.use(VueTheMask);
Vue.config.productionTip = false;
Vue.prototype.moment = moment;


// if (document.location.hostname === 'localhost') {
//   axios.defaults.baseURL = 'http://localhost:5000';
// }


const userToken = localStorage.getItem('user-token');
if (userToken) {
  identitySrv.SetAuthHeader(userToken);
}

Vue.prototype.$intervalDurationChatLoad = 5000;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
