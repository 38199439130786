import vue from 'vue';
import notificationsApi from '@/api/notifications';
import EventCardCorrelationRepository from '@/repositories/EventCardCorrelationRepository';

export default {
  namespaced: true,
  state: {
    notifications: [],
    eventCardCorrelationCount: 0,
    eventCardNotMappingCount: 0,
  },


  getters: {
    notifications: (state) => state.notifications,
    unreadNotificationsLength: (state) => state.notifications.filter((n) => !n.isRead).length,
    eventCardCorrelationCount: (state) => state.eventCardCorrelationCount,
    eventCardNotMappingCount: (state) => state.eventCardNotMappingCount,
    totalNotificationsLength: (state, getters) => getters.unreadNotificationsLength + state.eventCardCorrelationCount + state.eventCardNotMappingCount,
  },

  actions: {
    async getNotifications({ commit }) {
      const response = await notificationsApi.getNotifications();
      if (response && response.data) {
        commit('SET_NOTIFICATIONS', response.data);
      }
    },
    async makeNotificationRead({ commit }, notificationId) {
      await notificationsApi.makeNotificationRead(notificationId);
      commit('READ_NOTIFICATION', notificationId);
    },
    async makeAllUserNotificationsRead({ commit }) {
      await notificationsApi.makeAllUserNotificationsRead();
      commit('READ_ALL_NOTIFICATIONS');
    },
    async deleteNotification({ commit }, notificationId) {
      await notificationsApi.deleteNotification(notificationId);
      commit('DEL_NOTIFICATION', notificationId);
    },
    async deleteAllUserReadNotifications({ commit }) {
      await notificationsApi.deleteAllUserReadNotifications();
      commit('DEL_ALL_READ_NOTIFICATIONS');
    },
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },
    async loadEventCardCorrelationAsync({ state }, isExternal = true) {
      try {
        const params = {
          // statuses: this.filterModel.statuses,
        };
        params.statuses = isExternal ?  [2] : [1];
        // eslint-disable-next-line no-underscore-dangle
        state.eventCardCorrelationCount = await EventCardCorrelationRepository.getCount(params);

        if (isExternal) {
          state.eventCardNotMappingCount = await EventCardCorrelationRepository.getIntEventCardCorrelationCountAsync();
        } else {
          state.eventCardNotMappingCount = await EventCardCorrelationRepository.getExtEventCardCorrelationCountAsync();
        }
      } catch (e) {
        console.error(e);
      }
    },
  },

  mutations: {
    SET_NOTIFICATIONS: (state, notifications) => {
      state.notifications = notifications;
    },
    READ_NOTIFICATION: (state, notificationId) => {
      const note = state.notifications.find((t) => t.id === notificationId);
      if (note) {
        vue.set(note, 'isRead', true);
      }
    },
    READ_ALL_NOTIFICATIONS: (state) => {
      state.notifications.forEach((n) => vue.set(n, 'isRead', true));
    },
    DEL_NOTIFICATION: (state, notificationId) => {
      const index = state.notifications.findIndex((t) => t.id === notificationId);
      if (index > -1) {
        vue.delete(state.notifications, index);
      }
    },
    DEL_ALL_READ_NOTIFICATIONS: (state) => {
      state.notifications.splice(0);
    },
    ADD_NOTIFICATION: (state, notification) => {
      // console.log(notification);

      if (!state.notifications.some((t) => t.id === notification.id)) {
        state.notifications.unshift(notification);
      }
    },
  },
};
