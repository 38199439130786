import axios from 'axios';

export default {
  getNotifications: () => axios.get('/api/Notifications/GetNotifications'),
  makeNotificationRead: (id) => axios.post('/api/Notifications/MakeNotificationRead', { id }),
  makeAllUserNotificationsRead: () => axios.post('/api/Notifications/MakeAllUserNotificationsRead', {}),
  deleteNotification: (id) => axios.delete(`/api/Notifications/DeleteNotification/${id}`),
  deleteAllUserReadNotifications: () => axios.delete('/api/Notifications/DeleteAllUserReadNotifications'),
};

