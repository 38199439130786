import axios from 'axios';
import moment from 'moment';


export default {
  async loadMappingEventAsync(primaryEquipmentId, eventTime) {
    const dt = moment(eventTime)
      .local()
      .format('YYYY-MM-DDTHH:mm');
    const res = await axios.get('/api/EventCardCorrelation/GetEventIdsForCorrelation', {
      params: {
        primaryEquipmentId,
        eventTime: dt
      }
    });

    return res.data || [];
  },

  async getAsync(id) {
    const res = await axios.get(`/api/EventCardCorrelation/${id}`);
    return res.data;
  },

  // async saveAsync(data) {
  //   const res = await axios.put('/api/EventCardCorrelation', data);
  //   return res.data;
  // },
  async createAsync(data) {
    const res = await axios.post('/api/EventCardCorrelation', data);
    return res.data;
  },
  async updateAsync(data) {
    const res = await axios.put('/api/EventCardCorrelation', data);
    return res.data;
  },

  async getPagingAsync(params) {
    const res = await axios.post('/api/EventCardCorrelation/GetPaging', params);
    return {
      items: res.data?.items || [],
      totalCount: res.data?.totalCount || 0
    };
  },
  async getCount(params) {
    const res = await axios.post('/api/EventCardCorrelation/getCount', params);
    return res.data || 0;
  },

  async getCorrelationStatusesAsync() {
    const res = await axios.get('/api/EventCardCorrelation/GetCorrelationStatuses');
    return res.data || [];
  },

  async loadEventCardByIdAsync(id) {
    if (!id) return null;
    const res = await axios.get('/api/EventCardCorrelation/GetEventCardById', {
      params: {
        id
      }
    });
    return res.data;
  },

  async getExtEventCardByIdAsync(id) {
    if (!id) return null;
    const res = await axios.get('/api/EventCardCorrelation/GetExtEventCardById', {
      params: {
        id
      }
    });
    return res.data;
  },

  async getAllowedExtEventCards(eventTime, primaryEquipmentIds, secondEquipmentPanelIds) {
    const res = await axios.post('/api/EventCardCorrelation/GetAllowedExtEventCards', {
      eventTime,
      primaryEquipmentIds,
      secondEquipmentPanelIds
    });
    return res.data || [];
  },

  async getAllowedEventCards(eventTime, primaryEquipmentId, secondEquipmentPanelId) {
    const res = await axios.get('/api/EventCardCorrelation/GetAllowedEventCards', {
      params: {
        eventTime,
        primaryEquipmentId,
        secondEquipmentPanelId
      }
    });
    return res.data || [];
  },
  getPagingAvailableEventCardAsync(params) {
    return axios.post('/api/EventCardCorrelation/GetPagingAvailableEventCard', params);
  },
  getExtEventCardCorrelationPagingAsync(params) {
    return axios.post('/api/EventCardCorrelation/getExtEventCardCorrelationPaging', params);
  },
  getIntEventCardCorrelationPagingAsync(params) {
    return axios.post('/api/EventCardCorrelation/getIntEventCardCorrelationPaging', params);
  },
  deleteAsync(id) {
    return axios.delete(`/api/EventCardCorrelation/${id}`);
  },
  async getExtEventCardCorrelationCountAsync() {
    const res = await axios.get('/api/EventCardCorrelation/GetExtEventCardCorrelationCount');
    return res.data || 0;
  },
  async getIntEventCardCorrelationCountAsync() {
    const res = await axios.get('/api/EventCardCorrelation/GetIntEventCardCorrelationCount');
    return res.data || 0;
  },
};
