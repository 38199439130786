<template>
  <el-container id="app" class="theme-light">
    <el-header height="50px">
      <app-header />
    </el-header>
    <el-header >
      <app-sub-header />
    </el-header>
    <el-main v-if="showMain">
      <router-view/>
    </el-main>
    <el-footer  style="font-size: 10px; height: 12px"></el-footer>
    <theme />

  </el-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Theme from '@/components/Theme/ThemeDynamicLoader';
import AppHeader from '@/components/Layout/AppHeader';
import AppSubHeader from '@/components/Layout/AppSubHeader';
import signalrService from '@/utilities/signalrService';

export default {
  name: 'App',
  components: { AppSubHeader, Theme, AppHeader },
  data() {
    return {
      userLoaded: false,
    };
  },
  computed: {
    ...mapGetters('identity', ['user', 'isAuthenticated', 'token', 'userHasRoleGroup']),
    showMain() {
      return (this.isAuthenticated && this.user) || !this.isAuthenticated;
    }
  },
  async created() {
    if (this.isAuthenticated && !this.user) {
      signalrService.connect();
      // получить данные пользоватея
      await this.fetchUser();

      const user = { ...this.$store.getters['identity/user'] };
      const userStatusFirstAuth = user.statusId === 1;

      if (userStatusFirstAuth) {
        if (this.$route.name !== 'user-info') this.$router.push('user-info');
      }

      // получить уведомления для пользователя
      await this.getNotifications();
      await this.$store.dispatch('notifications/loadEventCardCorrelationAsync', this.userHasRoleGroup('ExternalUser'));
      // получить справочники
      await this.fetchDictionaries();

      // получить новости
      await this.getUserNews();
    }
  },
  methods: {
    ...mapActions('identity', [
      'fetchUser',
    ]),
    ...mapActions('news', [
      'getUserNews', 'addNews'
    ]),
    ...mapActions('notifications', [
      'getNotifications', 'addNotification'
    ]),
    ...mapActions('dictionaries', [
      'fetchDictionaries'
    ]),
  },
};
</script>

<!--фикс скроллов которые вылезают при загрузке приложения-->
<style lang="scss">
  body {
    overflow-y: hidden;
    overflow-x: hidden;
  }
</style>
